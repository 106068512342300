import { Component } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { StorageService } from 'src/app/authorized/services/storage.service';

@Component({
    selector: 'app-sidenav',
    templateUrl: './side-nav.component.html'
})

export class SideNavComponent {

    public menuItems: any[]
    isFolded: boolean;
    isSideNavDark: boolean;
    isExpand: boolean;

    constructor(private themeService: ThemeConstantService, private storageService: StorageService) { }

    ngOnInit(): void {

        const roles = this.storageService.getRoles();
        if (roles !== 'ROLE_property_manager') {
            this.menuItems = ROUTES.filter(menuItem => menuItem);
        } else {
            this.menuItems = [
                {
                    path: 'notice',
                    title: 'Notice',
                    iconType: 'nzIcon',
                    icon: 'windows',
                    iconTheme: 'outline',                   
                    submenu: []
                },
                {
                    path: 'publishing/program',
                    title: 'Publish',
                    iconType: 'nzIcon',
                    icon: 'send',
                    iconTheme: 'outline',
                    submenu: []
                }
            ]
        }

        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
    }

    closeMobileMenu(): void {
        if (window.innerWidth < 992) {
            this.isFolded = false;
            this.isExpand = !this.isExpand;
            this.themeService.toggleExpand(this.isExpand);
            this.themeService.toggleFold(this.isFolded);
        }
    }
}