import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { StorageService } from './storage.service';
import { PermissionService } from './permission.service';
import { SigninService } from './signin.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService implements CanActivate {
  constructor(
    private storageService: StorageService,
    private permissionService: PermissionService,
    private router: Router,
    private signinService: SigninService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    let url =
      state.url.indexOf('/') !== -1
        ? state.url.substring(1, state.url.length)
        : state.url;

    const role = this.storageService.getRoles();
    if (role === 'ROLE_property_manager' && (url === 'notice' || url == 'publishing/program')) {
      return of(true);
    } else if (role === 'ROLE_property_manager') {
      this.router.navigate(['/notice']);
      return of(false);
    }
    return of(true);
  }
}
